@import url('https://api.fontshare.com/v2/css?f[]=clash-grotesk@400,600,300,1,500&display=swap');

body {
  margin: 0;
  font-family: 'Clash Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Additional styles for centering content and other visual adjustments */
.container {
  max-width: 800px;
  margin: auto;
}

h2 {
  font-size: 2.5rem;
  margin-top: 2rem;
}

p {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.image-container {
  padding: 8px; /* Adjust padding to create space around the images */
}

.image-container .custom-image:first-child {
  margin-bottom: 15px; /* This is the space between the two images in a column */
}

.custom-image {
  width: 100%; /* Ensures the image is responsive and fits the width of its container */
  border-radius: 10px;
}

.custom-navbar {
  margin-top: 40px; /* Adjust the value as needed */
}

.no-padding-left {
  padding-left: 0 !important; /* Use !important to override Bootstrap's default padding */
}

.favorite-image {
  width: 100%; /* Make the image fill the width of the card */
  height: 200px; /* Set a fixed height for all images */
  object-fit: cover; /* This will cover the area, cropping the image as needed without stretching it */
}

.favorite-card {
  height: 180px; /* Adjust the height as needed to fit the content */
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(219, 218, 218); 
  border-radius: 5px;
}

.bold-nav-link {
  font-weight: semi-bold;
  font-size: 1.2em; 
  color: black;
}

.custom-button {
  color: inherit; /* Or any color you prefer */
  background-color: transparent;
  border: none;
  padding: 0;
  display: inline-flex;
  align-items: center;
  text-decoration: none; /* Optional: to remove underline from link buttons */
}

.custom-button svg {
  margin-right: 5px; /* Spacing between icon and text */
}

/* Header.css */
@media (max-width: 991px) { /* Adjust this value based on when you want the mobile layout to kick in */
  .custom-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-around; /* Or 'space-between', 'flex-start', etc., based on desired spacing */
  }

  .custom-nav .nav-link {
    padding-left: 0; /* Adjust or remove padding as needed */
    padding-right: 10px; /* Adjust or remove padding as needed */
  }

  .custom-button {
    font-size: 1.1em;
}
}

.react-button {
  color: #000000; /* React blue */
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(219, 218, 218); /* Border color similar to React logo */
  border-radius: 5px; /* Rounded corners */
  padding: 1px 4px; /* Padding inside the button */
  font-size: 0.9em; /* Font size for the text */
  display: inline-flex; /* Aligns the icon and text inline */
  align-items: center; /* Centers the icon and text vertically */
  text-decoration: none; /* Removes underline from links */
}

.react-button:hover, .react-button:focus, .react-button:active {
  color: #000000; 
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(219, 218, 218); /* Border color similar to React logo */
  border-radius: 5px; /* Rounded corners */
  padding: 1px 4px; /* Padding inside the button */
  font-size: 0.9em; /* Font size for the text */
  display: inline-flex; /* Aligns the icon and text inline */
  align-items: center; /* Centers the icon and text vertically */
  text-decoration: none; /* Removes underline from links */
}

.react-button svg {
  margin-right: 4px; /* Space between the icon and the text */
  color: #61DAFB; /* React blue */
}

.node-button {
  color: #000000; /* React blue */
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(219, 218, 218); /* Border color similar to React logo */
  border-radius: 5px; /* Rounded corners */
  padding: 1px 4px; /* Padding inside the button */
  font-size: 0.9em; /* Font size for the text */
  display: inline-flex; /* Aligns the icon and text inline */
  align-items: center; /* Centers the icon and text vertically */
  text-decoration: none; /* Removes underline from links */
}

.node-button:hover, .node-button:focus, .node-button:active {
  color: #000000; 
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(219, 218, 218); /* Border color similar to React logo */
  border-radius: 5px; /* Rounded corners */
  padding: 1px 4px; /* Padding inside the button */
  font-size: 0.9em; /* Font size for the text */
  display: inline-flex; /* Aligns the icon and text inline */
  align-items: center; /* Centers the icon and text vertically */
  text-decoration: none; /* Removes underline from links */
}

.node-button svg {
  margin-right: 4px; /* Space between the icon and the text */
  color: #5FA04E;
}

.arrow-icon {
  display: inline-block;
  transition: transform 0.1s ease-in-out;
  font-size: 2em;
}

.arrow-icon2 {
  display: inline-block;
  transition: transform 0.1s ease-in-out;
  font-size: 1.5em;
}

button:hover .arrow-icon2 {
  transform: rotate(-20deg);
}

/* Assuming the anchor tag wraps the entire card */
a:hover .arrow-icon {
  transform: rotate(-20deg);
}

.project-card {
  background-color: rgb(250, 250, 250);
  border-radius: 5px;
}

.linkedin {
  color: rgb(0, 0, 0);
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(219, 218, 218); /* Border color similar to React logo */
  border-radius: 5px; /* Rounded corners */
  padding: 1px 4px; /* Padding inside the button */
  font-size: 0.9em; /* Font size for the text */
  display: inline-flex; /* Aligns the icon and text inline */
  align-items: center; /* Centers the icon and text vertically */
  text-decoration: none; /* Removes underline from links */
}

.linkedin:hover {
  color: rgb(0, 0, 0);
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(219, 218, 218); /* Border color similar to React logo */
  border-radius: 5px; /* Rounded corners */
  padding: 1px 4px; /* Padding inside the button */
  font-size: 0.9em; /* Font size for the text */
  display: inline-flex; /* Aligns the icon and text inline */
  align-items: center; /* Centers the icon and text vertically */
  text-decoration: none; /* Removes underline from links */
}

.linkedin svg {
  color: rgb(10, 102, 194);
}

.send-button {
  color: #000000; 
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(219, 218, 218); /* Border color similar to React logo */
  border-radius: 5px; /* Rounded corners */
  padding: 1px 4px; /* Padding inside the button */
  font-size: 0.9em; /* Font size for the text */
  display: inline-flex; /* Aligns the icon and text inline */
  align-items: center; /* Centers the icon and text vertically */
  text-decoration: none; /* Removes underline from links */
}

.send-button:hover, .react-button:focus, .react-button:active {
  color: #000000; 
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(219, 218, 218); /* Border color similar to React logo */
  border-radius: 5px; /* Rounded corners */
  padding: 1px 4px; /* Padding inside the button */
  font-size: 0.9em; /* Font size for the text */
  display: inline-flex; /* Aligns the icon and text inline */
  align-items: center; /* Centers the icon and text vertically */
  text-decoration: none; /* Removes underline from links */
}





